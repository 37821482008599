/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/irhs-logo.svg";
import { fbIcon, twIcon, pnIcon, insIcon } from "../constant/images";

const MobileMenu = ({ activeMenu, setActiveMenu }) => {
	const [showHomeMenu, setShowHomeMenu] = useState(false);
	const [showPageMenu, setShowPageMenu] = useState(false);
	const [showBlogMenu, setShowBlogMenu] = useState(false);
	const [showCourseMenu, setShowCourseMenu] = useState(false);

	const handleShowHomeMenu = () => {
		setShowHomeMenu(!showHomeMenu);
	};
	const handleShowPageMenu = () => {
		setShowPageMenu(!showPageMenu);
	};
	const handleShowBlogMenu = () => {
		setShowBlogMenu(!showBlogMenu);
	};
	const handleShowCourseMenu = () => {
		setShowCourseMenu(!showCourseMenu);
	};

	return (
		<>
			<div className="openmobile-menu fixed top-0 h-screen pt-10 pb-6 bg-white shadow-box2 w-[320px] overflow-y-auto flex flex-col z-[999] active-mobile-menu">
				<div className="flex justify-between px-6 flex-none">
					<Link
						to={"/"}
						className="brand-logo flex-none mr-10 "
						style={{ maxWidth: 200, width: 200 }}
					>
						<img src={logo} alt="logo" />
					</Link>
					<span
						className=" text-3xl text-black cursor-pointer rt-mobile-menu-close"
						onClick={() => {
							setActiveMenu(!activeMenu);
						}}
					>
						<iconify-icon icon="fe:close"></iconify-icon>
					</span>
				</div>
				<div className="mobile-menu mt-6 flex-1 ">
					<ul className="menu-active-classNames">
						<li
							className={`${showHomeMenu ? "open" : ""}`}
						>
							<a href="#tuesdays-at-ridge" onClick={() => setActiveMenu(!activeMenu)}>Tuesdays @ The Ridge</a>
						</li>
						{/* <li
							className={`menu-item-has-children ${showPageMenu ? "open" : ""}`}
							onClick={handleShowPageMenu}
						>
							<a href="#">Workshops</a>
							<ul
								className="sub-menu"
								style={
									showPageMenu ? { display: "block" } : { display: "none" }
								}
							>
								<li>
									<a href="#workshops-past" onClick={() => setActiveMenu(!activeMenu)}>Past Workshops</a>
								</li>
								<li>
									<a href="#workshops-upcoming" onClick={() => setActiveMenu(!activeMenu)}>Upcoming Workshops</a>
								</li>
							</ul>
						</li> */}
						<li
							className={`menu-item-has-children ${showCourseMenu ? "open" : ""
								}`}
							onClick={handleShowCourseMenu}
						>
							<a href="#">Events</a>
							<ul
								className="sub-menu"
								style={
									showCourseMenu ? { display: "block" } : { display: "none" }
								}
							>
								<li>
									<a href="#events-past" onClick={() => setActiveMenu(!activeMenu)}>Past Events</a>
								</li>
								<li>
									<a href="#events-upcoming" onClick={() => setActiveMenu(!activeMenu)}>Upcoming Events</a>
								</li>
							</ul>
						</li>
						<li>
							<a href="https://irs.hdsb.ca/" target="_blank" rel="noreferrer" onClick={() => setActiveMenu(!activeMenu)}>IRHS Site</a>
						</li>
						{/* <li
							className={`${showBlogMenu ? "open" : ""}`}
							onClick={handleShowBlogMenu}
						>
							<a href="#footer" onClick={() => setActiveMenu(!activeMenu)}>Volunteer Sign Up</a>
						</li> */}
					</ul>
				</div>
				<div className=" flex-none pb-4">
					<div className=" text-center text-black font-semibold mb-2">
						Follow Us
					</div>
					<ul className="flex space-x-4 justify-center ">
						<li>
							<a href="https://www.facebook.com/IRHSPARENTS" target="_blank" rel="noreferrer" className="flex h-10 w-10">
								<img src={fbIcon} alt="fbIcon" />
							</a>
						</li>
						<li>
							<a href="https://twitter.com/HaltonDSB" target="_blank" rel="noreferrer" className="flex h-10 w-10">
								<img src={twIcon} alt="twiter" />
							</a>
						</li>
						<li>
							<a href="https://www.instagram.com/irhsparents" target="_blank" rel="noreferrer" className="flex h-10 w-10">
								<img src={insIcon} alt="insIcon" />
							</a>
						</li>
					</ul>
				</div>
			</div>{" "}
			<div className={`rt-mobile-menu-overlay ${activeMenu && "active"}`}></div>
		</>
	);
};

export default MobileMenu;
