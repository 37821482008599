import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import {
	loadCaptchaEnginge,
	LoadCanvasTemplate,
	validateCaptcha,
} from '../utils/react-simple-captcha';
import moment from 'moment'
import mailIcon from "../assets/images/icon/mail.svg";
import userIcon from "../assets/images/icon/user.svg";
import checkIcon from "../assets/images/icon/ck.svg";
import { getPastEvents, getUpcomingWorkshops, getPastWorkshops, getUpcomingEvents, postNewsLetterEntry, getPresentations } from '../constant/services/home';
import { Link } from "react-router-dom";

const Banner = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [captcha, setCaptcha] = useState('');
	const localizer = momentLocalizer(moment);
	const [eventList, setEventList] = useState([]);

	const getEventObj = (eventsArr = [], eventType = '') => {
		const allEvents = [];
		eventsArr.forEach((pEvent) => {
			const sMonth = new Date(pEvent?.attributes?.date).getUTCMonth();
			const sDate = new Date(pEvent?.attributes?.date).getUTCDate();
			const sYear = new Date(pEvent?.attributes?.date).getUTCFullYear();
			const sHours = new Date(pEvent?.attributes?.date).getUTCHours();
			const sMinutes = new Date(pEvent?.attributes?.date).getUTCMinutes();
			allEvents.push({
				id: pEvent?.id,
				eventType,
				title: pEvent?.attributes?.title,
				start: new Date(sYear, sMonth, sDate, sHours, sMinutes),
				end: new Date(sYear, sMonth, sDate, sHours + 1, sMinutes),
			})
		});

		return allEvents;
	}

	const fetchCalendarData = async () => {
		let totalEvents = [];

		let pastEvents = await getPastEvents();
		pastEvents = pastEvents?.data || [];

		// let pastWorkshops = await getPastWorkshops();
		// pastWorkshops = pastWorkshops?.data || [];

		let upcomingEvents = await getUpcomingEvents();
		upcomingEvents = upcomingEvents?.data || [];

		// let upcomingWorkshop = await getUpcomingWorkshops();
		// upcomingWorkshop = upcomingWorkshop?.data || [];

		let tuesdaysAtRidge = await getPresentations();
		tuesdaysAtRidge = tuesdaysAtRidge?.data || [];

		totalEvents = [...getEventObj(pastEvents, 'events-past'), ...getEventObj(upcomingEvents, 'events-upcoming'), ...getEventObj(tuesdaysAtRidge, 'tuesdays-at-ridge')];
		setEventList(totalEvents);
	};

	useEffect(() => {
		fetchCalendarData();
		// loadCaptchaEnginge(6);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEventSelect = (event) => {
		document.getElementById(event.eventType).scrollIntoView();
	};

	const MyCalendar = (props) => (
		<div style={{
			background: "white",
			height: 450,
			position: "relative",
			right: 0,
			maxWidth: 500,
			width: '100%',
			borderRadius: 16,
		}} className="img-cal-inner">
			<Calendar
				localizer={localizer}
				events={eventList}
				startAccessor="start"
				endAccessor="end"
				style={{
					height: 450,
					border: "1px solid #ccc",
					borderRadius: 16,
					color: "rgb(48 190 173 / var(--tw-text-opacity))"
				}}
				views={{
					month: true,
				}}
				showAllEvents
				onSelectEvent={handleEventSelect}
			/>
		</div>
	)

	const onSubmit = async () => {
		if (!name || !email || !captcha) {
			alert("Please enter all the details.")
			return;
		}
		if (!validateCaptcha(captcha)) {
			alert('Captcha validation failed.');
			return;
		}
		await postNewsLetterEntry({
			data: {
				name,
				email
			}
		});
		setEmail('');
		setName('');
		setCaptcha('');
		loadCaptchaEnginge(6);
	}

	return (
		<section
			className={`xl:min-h-screen bg-[url('../images/banner/banner.png')] bg-cover bg-no-repeat bg-center overflow-hidden`}
		>
			<div className="container relative">
				<div className="max-w-[590px] xl:pt-[297px] md:pt-20 md:pb-20 pt-28 pb-14 xl:pb-40 space-y-8">
					<h1>
						<span className=" text-secondary inline-block bg-[url('../images/banner/shape.svg')]  bg-no-repeat bg-bottom">
							Iroquois Ridge
						</span>{" "}
						<span style={{ fontSize: 40, lineHeight: '46px' }}>Parent Engagement Program</span>
					</h1>
					<div className=" plain-text text-gray leading-[30px]" style={{ textTransform: 'none', marginTop: 10 }}>
						The Iroquois Ridge Parent Engagement Program is an initiative run by a group of volunteer parents who have been organizing some amazing speakers once a month for our parent community, and supporting the school by volunteering at events. We look forward to continued engagement between the parent body and larger school community.
						{/* <br />
						<br />
						To get involved, sign up here for regular updates */}
					</div>
					{/* <div className="bg-white md:flex  rounded-md shadow-e1 items-center py-[10px] relative lg:pr-[10px] lg:pl-[38px] pl-4 pr-4" style={{ maxWidth: 400, flexDirection: 'column' }}>
						<div style={{ display: 'flex' }}>
							<div className="flex-1 flex items-center mb-2">
								<div className="flex-none">
									<span className=" ">
										<img src={userIcon} alt="mainIcon" />
									</span>
								</div>
								<div className="flex-1">
									<input
										type="text"
										placeholder="Enter Name"
										className="border-none focus:ring-0"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
							</div>
							<div className="flex-1 flex items-center mb-2 ml-4">
								<div className="flex-none">
									<span className=" ">
										<img src={mailIcon} alt="mainIcon" />
									</span>
								</div>
								<div className="flex-1">
									<input
										type="text"
										placeholder="Enter E-mail"
										className="border-none focus:ring-0"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<div className="flex-1 flex items-center mb-2 captcha-container"><LoadCanvasTemplate /></div>
							<div className="flex-1 flex items-center  mb-2 ml-4">
								<div className="flex-none">
									<span className=" ">
										<img src={checkIcon} alt="mainIcon" />
									</span>
								</div>
								<div className="flex-1">
									<input
										type="text"
										placeholder="Enter captcha"
										className="border-none focus:ring-0"
										value={captcha}
										onChange={(e) => setCaptcha(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className="flex-none">
							<button className="btn btn-primary w-full lg:w-auto" onClick={() => onSubmit()} style={{ padding: 10 }}>
								Submit
							</button>
						</div>
						<div style={{ fontSize: 12, textTransform: 'none' }}>By using this site you agree to <Link to="/tnc" style={{ color: '-webkit-link', textDecoration: 'underline' }}>T&C.</Link></div>
					</div> */}
				</div>
				<div className="imge-box absolute xl:right-[-260px]  hidden xl:block   bottom-0  image-calender " style={{ top: '30%' }}>
					{MyCalendar()}
				</div>
			</div>
		</section>
	);
};

export default Banner;