/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import footerLogo from "../assets/images/logo/irhs-logo.svg";
import { postNewsLetterEntry, volunteerSignUp } from '../constant/services/home';
import mailIcon from "../assets/images/icon/mail.svg";
import userIcon from "../assets/images/icon/user.svg";
import phoneIcon from "../assets/images/icon/headphone.svg";
import checkIcon from "../assets/images/icon/ck.svg";
import { LoadCanvasTemplate2, loadCaptchaEnginge2, validateCaptcha2 } from "../utils/react-simple-captcha";
import { Link } from "react-router-dom";

const Footer = () => {
	const updatedDate = new Date().getFullYear();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phonenumber, setPhoneNumber] = useState('');
	const [captcha, setCaptcha] = useState('');

	const onSubmit = async () => {
		if (!name || !email || !captcha) {
			alert("Please enter all the details.")
			return;
		}
		if (!validateCaptcha2(captcha)) {
			alert('Captcha validation failed.');
			return;
		}
		await volunteerSignUp({
			data: {
				name,
				email,
				phonenumber
			}
		});
		await postNewsLetterEntry({
			data: {
				name,
				email
			}
		});
		alert('Thank You for Signing Up. We will get back to you soon.')
		setEmail('');
		setName('');
		setPhoneNumber('');
		setCaptcha('');
		loadCaptchaEnginge2(6);
	};

	// useEffect(() => {
	// 	loadCaptchaEnginge2(6);
	// }, []);

	return (
		<footer className="bg-black bg-[url('../images/all-img/footer-bg-1.png')] bg-cover bg-center bg-no-repeat" id="footer">
			<div className="section-padding container">
				<div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-2">
					<div className="single-footer">
						<div className="lg:max-w-[270px]">
							<a href="#" className="mb-10 block">
								<img src={footerLogo} alt="" />
							</a>
							<p style={{textTransform: 'none',}}>
							IRHSParents.com is an independent initiative run by a group of volunteer parents. This website is neither affiliated with nor related to Iroquois Ridge High School or Halton School District Board. 
							</p>
							<ul className="flex space-x-4 pt-8">
								<li>
									<a
										href="https://www.facebook.com/IRHSPARENTS"
										className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
										target="_blank" rel="noreferrer"
									>
										<iconify-icon icon="bxl:facebook"></iconify-icon>
									</a>
								</li>
								<li>
									<a
										href="https://twitter.com/HaltonDSB"
										className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
										target="_blank" rel="noreferrer"
									>
										<iconify-icon icon="bxl:twitter"></iconify-icon>
									</a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/irhsparents"
										className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
										target="_blank" rel="noreferrer"
									>
										<iconify-icon icon="bxl:instagram"></iconify-icon>
									</a>
								</li>
							</ul>
						</div>
					</div>
					{/* <div className="single-footer" style={{ maxWidth: 450 }}>
						<h4 className="mb-8 text-2xl font-bold text-white">Volunteer Sign Up</h4>
						<div className="flex">
							<div className="mb-4 flex items-center rounded-md bg-white py-[10px] pl-6">
								<div className="flex-none">
									<span className=" ">
										<img src={userIcon} alt="" />
									</span>
								</div>
								<div className="flex-1">
									<input
										type="text"
										placeholder="Enter Name"
										className="border-none focus:ring-0"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
							</div>
							<div className="mb-4 flex items-center rounded-md bg-white py-[10px] pl-6 shadow-e1 ml-4">
								<div className="flex-none">
									<span className=" ">
										<img src={mailIcon} alt="" />
									</span>
								</div>
								<div className="flex-1">
									<input
										type="text"
										placeholder="Enter E-mail"
										className="border-none focus:ring-0"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
							<div className="flex-none">
								<span className=" ">
									<img src={phoneIcon} alt="" />
								</span>
							</div>
							<div className="flex-1">
								<input
									type="text"
									placeholder="Enter Phonenumber"
									className="border-none focus:ring-0"
									value={phonenumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</div>
						</div>
						<div className="flex">
							<div className="mb-4 flex items-center rounded-md bg-white py-[10px] shadow-e1 captcha-container2">
								<LoadCanvasTemplate2 />
							</div>
							<div className="mb-4 flex items-center rounded-md bg-white py-[10px] pl-6 shadow-e1 ml-4">
								<div className="flex-none">
									<span className=" ">
										<img src={checkIcon} alt="mainIcon" />
									</span>
								</div>
								<div className="flex-1">
									<input
										type="text"
										placeholder="Enter Captcha"
										className="border-none focus:ring-0"
										value={captcha}
										onChange={(e) => setCaptcha(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<button className="btn btn-primary block w-full text-center" onClick={onSubmit}>
							Submit
						</button>
						<div style={{ fontSize: 12, textTransform: 'none', textAlign: 'center' }}>By using this site you agree to <Link to="/tnc" style={{ color: '-webkit-link', textDecoration: 'underline' }}>T&C.</Link></div>
					</div> */}
				</div>
			</div>
			<div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-base">
				&copy; Copyright {updatedDate} | IRHS - Parent Engagement Program | All Rights Reserved | Supported by <a href='https://www.zirkeltech.com/' target="_blank" rel="noreferrer">Zirkel Technologies (www.zirkeltech.com)</a>
			</div>
		</footer>
	);
};

export default Footer;
